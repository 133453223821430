<template>
    <main class="profile d-flex justify-content-between box-white-rec" v-if="datas">
            <div class="profile__myProfile col-6 col-sm-12">
                <div class="profile__inputGroup">
                    <div class="form-input">
                        <p class="title-top-input">{{labels.username.title}}</p> 
                        <input type="text" disabled class="form-control form-cs" v-model="mutatedData.username">
                    </div>
                    <div class="form-input">
                        <p class="title-top-input">{{labels.new_password.title}}</p> 
                        <input type="password" class="form-control form-cs" v-model="password">
                    </div>
                    <div class="form-input">
                        <p class="title-top-input">{{labels.repeat_password.title}}</p>
                        <input type="password" class="form-control form-cs" v-model="confirm_password">
                    </div>
                </div>
                <div class="btn-save-profile" v-bind:class="{'btn-blue btn-border-blue': isUserFrench, 'btn-orange btn-border-orange': !isUserFrench}" @click="changePassword()">{{$t('Change Password')}}</div>
            </div>
            <div class="profile__yourInformation col-6 col-sm-12">
                <div class="profile__inputGroup">
                    <div class="form-input">
                        <p class="title-top-input">{{labels.firstname.title}}</p> 
                        <input type="text" class="form-control form-cs" v-model="mutatedData.name">
                    </div>
                    <div class="form-input">
                        <p class="title-top-input">{{labels.lastname.title}}</p>
                        <input type="text" class="form-control form-cs" v-model="mutatedData.lastname">
                    </div>
                    <div class="form-input">
                        <p class="title-top-input">{{labels.email.title}}</p> 
                        <input type="text" disabled class="form-control form-cs" v-model="mutatedData.email">
                    </div>
                    <div class="form-input">
                        <p class="title-top-input">{{labels.job_title.title}}</p>
                        <input type="text" class="form-control form-cs" v-model="mutatedData.jobtitle">
                    </div>
                    <div class="form-input">
                        <p class="title-top-input">{{labels.phone.title}}</p> 
                        <input type="text" class="form-control form-cs" v-model="mutatedData.telephone">
                    </div>
                    <div class="form-input">
                        <p class="title-top-input">{{labels.country.title}}</p>
                        <input type="text" class="form-control form-cs" v-model="mutatedData.mailing_country">
                    </div>
                </div>
                <div class="btn-save-info" v-bind:class="{'btn-blue btn-border-blue': isUserFrench, 'btn-orange btn-border-orange': !isUserFrench}" @click="saveInformations()">{{$t('Save')}}</div>
            </div>
        </main>
</template>

<script>
import { isUserFrench } from '@/utils'
    export default {
        name:'Informations',
        props: {
            datas: Object
        },
        data: function(){
            return {
                labels: {
                    username : {
                        title : this.$t('profile.username')
                    },
                    new_password : {
                        title : this.$t('profile.new_password')
                    },
                    repeat_password : {
                        title : this.$t('profile.repeat_password')
                    },
                    firstname : {
                        title : this.$t('profile.firstname')
                    },
                    lastname : {
                        title : this.$t('profile.lastname')
                    },
                    email : {
                        title : "Email"
                    },
                    job_title : {
                        title : this.$t('profile.job_title')
                    },
                    phone : {
                        title : this.$t('profile.phone')
                    },
                    country : {
                        title : this.$t('profile.country')
                    },
                },
                errors: [],
                password: null,
                confirm_password: null
            }
        },
        methods: {
            saveInformations() {
                const that = this
                const data = this.mutatedData
                this.$store.dispatch("users/userUpdate", {
                    lastname: data.lastname, 
                    name: data.name, 
                    email: data.email,
                    telephone: data.telephone,
                    mailing_country: data.mailing_country,
                    jobtitle: data.jobtitle
                }).then(function(){
                    that.$toasted.show('Vos informations ont été mise à jour', {icon:'fa-check', className: 'notif-success'})
                }, function(error){
                    const errorMsg = Object.values(error.errors).join('<br>')
                    that.$toasted.show(errorMsg, {icon:'fa-times', className: 'notif-error'})
                    that.$store.dispatch("users/userData");
                })
            },
            changePassword() {
                let errors = [];

                if(!this.password || !this.confirm_password)
                    errors.push("Password and confirm password required");
                else if(this.password !== this.confirm_password)
                    errors.push("Passwords not matched")

                if(errors.length > 0){
                    const errorMsg = errors.join('<br>')
                    this.$toasted.show(errorMsg, {icon:'fa-times', className: 'notif-error'})
                } 

                //TODO: regex check
                const that = this
                this.$store.dispatch("users/userUpdate", {
                    password: this.password,
                    new_password: this.confirm_password
                }).then(function(response){
                    if(response.success)
                        that.$toasted.show(response.message, {icon:'fa-check', className: 'notif-success'})
                }, function(error){
                    console.log(error);
                    const errorMsg = Object.values(error.errors).join('<br>')
                    that.$toasted.show(errorMsg, {icon:'fa-times', className: 'notif-error'})
                })
            }
        },
        computed: {
            isUserFrench,
            mutatedData() {
                return this.datas.data
            }
        }
    }
</script>
<style scoped> 
.profile{
    width:100%;
    margin: 10px 0;
    padding: 20px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
    border-radius: 0;
}

.profile__myProfile, 
.profile__yourInformation {
    width: 50%;
    padding: 0 10px;
}

h2 {
    font-size: 15px;
    margin: 0 0 15px;
    font-weight: 600;
    color: #232426;
}

.btn-save-profile, .btn-save-info {cursor: pointer;float: left;}

@media (max-width: 700px){
    .profile{flex-direction: column !important;}
    .profile__myProfile, .profile__yourInformation{width: 100%;margin-bottom: 40px;}
}
@media (max-width: 600px){
    .profile{padding: 10px 15px;}
}
@media only screen and (max-height: 850px) {
    .profile{
        margin-bottom: 5px;
        padding: 10px 15px;
    }
}

</style>
