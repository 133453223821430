<template>
    <div >
        <Header />
        <div class="container-fluid" id="profile">
            <div class="wrapper-box wrapper-box-keyword" style="min-height: 583px;">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12"><h1 class="title-researchs">{{$t('My Profile')}}</h1></div>                                    
                    <div class='select-buttons d-flex'>
                        <div v-bind:class="{ 
                            'btn-blue': (selectedInformations && isUserFrench), 
                            'btn-border-blue': isUserFrench, 
                            'btn-border-orange': !isUserFrench,
                            'btn-orange': selectedInformations && !isUserFrench}" 
                            v-on:click="selectTab">{{$t('Profile & Information')}}</div>
                        <div v-bind:class="{ 
                            'btn-blue': (!selectedInformations && isUserFrench), 
                            'btn-border-blue': isUserFrench, 
                            'btn-border-orange': !isUserFrench,
                            'btn-orange': !selectedInformations && !isUserFrench}" v-on:click="selectTab">{{$t('Email Alerts')}}</div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <Informations v-if="selectedInformations" :datas="profile"/>
                        <EmailsAlerts v-else 
                            :alerts="data" 
                            :agencies="profile.data.access_agency"
                        />
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Loader :isLoad="isLoad" />
    </div>    
</template>
<style>
    .select-buttons {
        margin: 0 0 20px 10px;
    }
    .select-buttons > div {
        margin-right: 10px;
        cursor: pointer;
    }
    .wrapper-box-keyword {
        margin: 0px 85px 35px 85px;
        padding-top: 25px;
    }
</style>
<script>
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import Informations from "@/components/profile/Informations.vue";
import EmailsAlerts from "@/components/profile/EmailsAlerts.vue";
import Loader from "@/components/Layout/Loader";
import { alertTypes, isUserFrench } from "@/utils"
export default {
    name : 'userdocument',
    components:{
        Header,
        Footer,
        Informations,
        EmailsAlerts,
        Loader
    },
    data() {
        return {
            selectedInformations: true,
            status : {} ,
            documentation : {},
            data: []
        }
    },
    methods: {
        selectTab() {
            this.selectedInformations = !this.selectedInformations
        }
    },
    computed: {
        alerts(){
            return this.$store.getters['users/user_alerts']
        },
        profile(){
            return this.$store.getters['users/user_profile']
        },
        isLoad() {  
            return this.$store.getters["users/load"]
        },
        isUserFrench
    },
    mounted() {
        this.$store.dispatch("users/userData");
        this.$store.dispatch("users/userAlerts");  
    },
    watch: {
        alerts() {
            this.data = this.alerts.data.filter(alert => {
                return alertTypes.includes(alert.type)
            })
        }
    }
}
</script>
